<template>
    <div>
        <div class="top_side" id="contact_rm">
            <div class='text-bg'>
                <p class="display-3 orange--text" style="font-weight:bold;">Contactez Nous</p>
                <p class="subtitle white--text">additionnel description de cette page</p>
            </div>
        </div>
        <div class="container px-auto mx-auto">
            <div class="px-auto my-15 mx-auto">
                <v-card elevation="3" align="center" class="content-bg py-15 px-auto mx-auto">
                    <div class="content mx-auto justify-content-center">
                        <v-card-title>
                            <v-col sm="12" justify="center">
                                <h1 class="">Envoyez-nous un message</h1>
                            </v-col>
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field label="Nom" required>
                                    </v-text-field>
                                </v-col>
                            </v-row> 
                            <v-row>
                                <v-col cols="12" >
                                    <v-text-field label="Prenom" required>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" >
                                    <v-text-field label="Email" required>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" >
                                    <v-textarea label="Message" solo required>

                                    </v-textarea>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions class="my-3">
                            <v-col sm="12">
                                <v-btn color="orange" large block outlined class="white--text">
                                    <v-icon dark left>
                                        mdi-send
                                    </v-icon>
                                    Envoyer 
                                </v-btn>
                            </v-col>
                        </v-card-actions>
                    </div>
                </v-card>
            </div>
        </div>
    </div>
</template>

<style>
    .content{
        width:600px;
    }

    .top_side{
        widows: 100%;
        height:27rem;
        /* background-color:gray; */
        margin-bottom:12px;
        background-size:cover  ;
       background: rgb(30,67,124);
        background: linear-gradient(0deg, rgba(9, 44, 97, 0.555) 0%, rgba(12, 38, 78, 0.878) 100%),url("../assets/cover11.jpg");
        background-repeat: no-repeat;
        background-size:cover;
        background-position: center;
       padding-top: 8rem;
       padding: auto;
        /* background-position-y: center; */
    }
    .content-bg{
        background: rgb(255,255,255);
        background: linear-gradient(90deg, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0.5) 100%),url("../assets/white-paper.jpg");
        background-repeat: no-repeat;
        background-position: right bottom;
        /* background-size: cover; */
    }

</style>

<script>
export default {
                      
}
</script>