<template>
    <div>
        <About/>
    </div>
</template>
<script>
import About from "@/components/About.vue";

export default {
  name: "Home",
  components: {
    About,
  },
};
</script>