<template>
<div>
  <br><br><br>
    <!-------------------- carousels ---------------------- -->
    <div style="" id="home_rm">
    <v-carousel height="400" show-arrows-on-hover app>
      <v-carousel-item src="../assets/bd_carr_1.jpg" gradient="to top right, rgba(0,0,100,.33), rgba(0,0,0,.7)" style="background-size:100% 100% !important;">
        <div style="width: 100%; height: 100%; padding-top: 110px !important;background-size:100% 100% !important;">
          <h1 class="display-2 orange--text my-2" style="font-weight: bold">
            Location de Voiture en ligne au Maroc
          </h1>
          <p class="subtitle-1">
            <!-- <strong> -->
            Offres Spéciales . Les tarifs aussi bas que possible. Essayez les!
            <!-- </strong> -->
          </p>
          <p style="margin-top: -15px; font-size: 20px" class="subtitle-2">
            <!-- <strong>  -->
          <!-- <transition 
          appear
          @before-enter="beforeEnter"
          @enter="enter"
          > -->
            <!-- <div v-if="animation"> -->
            nous garantissons un rapport qualité-prix !
            <!-- </div> -->
          <!-- </transition> -->
            <!-- </strong> -->
          </p>
              <v-btn color="warning" rounded large href="#form_rm" class="" style="margin-top:15px !important; height:55px;">réserver maintenant</v-btn>
        </div>
      </v-carousel-item>
      <v-carousel-item src="../assets/travel-car-children-1.jpg" gradient="to top right, rgba(0,0,100,.33), rgba(0,0,0,.7)">
        <div style="width: 100%; height: 100%; padding-top: 110px !important;">
          <h1 class="display-2 orange--text my-2" style="font-weight: bold">
            Location de Voiture Enligne au Maroc
          </h1>
          <p class="subtitle-1">
            <!-- <strong> -->
            Offres Spéciales . Les tarifs aussi bas que possible. Essayez les!
            <!-- </strong -->
          </p>
          <p style="margin-top: -15px; font-size: 20px" class="subtitle-2">
            <!-- <strong>  -->
            nous garantissons un rapport qualité-prix !
            <!-- </strong> -->
          </p>
          <v-btn color="warning" rounded large href="#form_rm" class="" style="margin-top:15px !important; height:55px;">réserver maintenant</v-btn>
        </div>
      </v-carousel-item>
      <v-carousel-item src="../assets/Road-Trip-Guide.jpg" gradient="to top right, rgba(0,0,100,.33), rgba(0,0,0,.7)">
        <div style="width: 100%; height: 100%; padding-top: 110px !important;">
          <h1 class="display-2 orange--text my-2" style="font-weight: bold">
            Location de Voiture Enligne au Maroc
          </h1>
          <p class="subtitle-1">
            <!-- <strong> -->
            Offres Spéciales . Les tarifs aussi bas que possible. Essayez les!
            <!-- </strong -->
          </p>
          <p style="margin-top: -15px; font-size: 20px" class="subtitle-2">
            <!-- <strong>  -->
            nous garantissons un rapport qualité-prix !
            <!-- </strong> -->
          </p>
          <v-btn color="warning" rounded large href="#form_rm" class="" style="margin-top:15px !important; height:55px;">réserver maintenant</v-btn>
        </div>
      </v-carousel-item>
    </v-carousel>
  </div>

<div id="form_rm"></div>
      <!-- --------------------------form remplissage--------------------- -->
      <div class="container my-12 mx-auto" id="">
        <v-card elevation="3" class="py-8 px-15 mx-auto" max-width="1100">
          <v-card-title class="primary--text mb-3" style="justify-content: center">
            <h2>Réservez  votre  voiture</h2>
          </v-card-title>
          <v-row align="center" justify="center">
            <v-col cols="12" sm="6">
              <v-combobox v-model="itemSearch.villeD" :items="itemsCity" item-text="name" item-value="id" prepend-icon="mdi-city" label="Ville de départ" id="ville_dr"></v-combobox>
            </v-col>
            <v-col cols="12" sm="6">
              <v-combobox v-model="itemSearch.villeF" :items="itemsCity" item-text="name" item-value="id" prepend-icon="mdi-home-city" label="Ville de dépôt" id="ville_dp" ></v-combobox>
            </v-col>
            <v-col cols="12" sm="3">
                <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="itemSearch.dateD" label="Date de départ..." prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="itemSearch.dateD" @input="menu1 = false" locale="fr"></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field v-model="itemSearch.timeD" label="Heure de départ..." prepend-icon="mdi-clock-time-eight-outline" type="time"></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="itemSearch.dateF" label="Date de dépôt..." prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="itemSearch.dateF" @input="menu2 = false" locale="fr"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field v-model="itemSearch.timeF" label="Heure de dépôt..." prepend-icon="mdi-clock-time-eight-outline" type="time"></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
                <v-select v-model="itemSearch.marque" :items="marques" prepend-icon="mdi-car-outline" label="Marque..." id="Marque"></v-select>
            </v-col>
            <v-col cols="12" sm="4">
                <v-select v-model="itemSearch.combustion" :items="combustions" prepend-icon="mdi-gas-station" label="Carburants..." id="combustion"></v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field v-model="itemSearch.desc" prepend-icon="mdi-shield-car" label="Modèle..." id="modele"></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-btn block color="warning accent-4" large rounded depressed @click="search()" elevation="4"> <v-icon left>mdi-magnify</v-icon>Voir nos offres</v-btn>
            </v-col>
          </v-row>
        </v-card>
      </div>

    <!-- <div class="container"> -->
    <div>
      <v-row align="center" justify="center" class="my-12">
        <v-col>
          <!-- <h1>Trouvons un bon deal pour votre voiture de location</h1> -->
          <h1>
            Sélectionnez la meilleure offre pour vous parmi toutes les offres
            disponibles!
          </h1>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" sm="4">
          <v-icon size="70" color="primary">mdi-phone-outline</v-icon>
          <h3 class="my-5">Réserver par téléphone</h3>
          <P>description simple</P>
          <v-btn large rounded color="warning" depressed>
            0532090902
          </v-btn>
        </v-col>
        <v-col cols="12" sm="4">
          <v-icon size="70" color="primary">mdi-monitor</v-icon>
          <h3 class="my-5">Réserver en ligne</h3>
          <div id="form_rm2"></div>
          <P>description simple</P>
          <v-btn large rounded color="warning" depressed> Reserver </v-btn>
        </v-col>
        <v-col cols="12" sm="4">
          <v-icon size="70" color="primary">mdi-email-outline</v-icon>
          <h3 class="my-5">Contactez Nous</h3>
          <P>description simple</P>
          <v-btn large rounded color="warning " depressed> Contactez Nous </v-btn>
        </v-col>
      </v-row>
        <!------------------- LES VOITURES --------------------->
      <div>
        <v-row class="mt-10 mb-5">
            <v-col>
              <h2 class="primary--text">Les voitures disponibles</h2>
            </v-col>
        </v-row>
        <v-row class="mx-auto justify-content-center">
          <v-col cols="12" sm="2" class="mx-auto justify-content-center">
             <v-card elevation="2" width="100%" style="padding:10px !important;">
              <v-card-title class="orange--text mb-6" style="justify-content: center">
                <h5>
                  BESOIN D'AIDE ?
                </h5>
              </v-card-title>
              <v-divider></v-divider>
              Si vous avez une question, n'hésitez pas à nous contacter ou de nous appeler sur notre numéro de téléphone.
              <br>
              <v-divider></v-divider>
              <br>
              +212 5-32-09-09-02
              <br>
              <v-divider></v-divider>
              <br>
              contact@gesloc.ma
              <br>
              <v-divider></v-divider>
              <!-- <br> -->
              </v-card>
          </v-col>
          
          <v-col cols="12" sm="8" style="padding:0px !important;">
        <v-row class="mx-auto justify-content-center">
            <!-- <div > -->
              <v-col cols="12" sm="4" style="padding:3px !important;" v-for="info in infos" :key="info.id">
                  <v-card class="vcard mx-auto crd_effect text-start my-3" max-width="330" style="padding:0px !important;background-color:#fcfcfc">
                  <div class="">
                    <img :src="info.image" class="white--text align-end" height="235px" width="100%">
                  </div>
                <div style="">
                    <v-card-title class="pb-0 pt-1">
                        <h5 style="text-transform: Capitalize; font-weight: bold">{{ info.marque }}</h5><h5 style="margin-left:10px; text-transform: Capitalize; font-weight: bold">{{ info.desc }}</h5>
                    </v-card-title>
                    <v-card-text class="d-flex mx-auto p-0" style="width:19rem;">
                      <!-- <div class="mr-4 border border-light" style="align-items:center; width:95px; height:80px;">
                        <img :src="info.companyLogo" alt="company logo" class="" height="100%" width="100%">
                      </div> -->
                      <!-- <p style="font-size:large;">Agence : <span class="primary--text">{{info.companyName}} </span><br><br> Ville : <span class="primary--text">{{info.companyCity}} </span></p> -->
                    </v-card-text>
                    <!-- <br> -->
                  <div class="">
                    <v-list-item class="bg-white mt-1 mx-auto">
                        <v-list-item-content>
                        <!-- <p class="" style="color:gray;">Additionnelle infos</p> -->
                        <v-list-item-title class="my-1 lst_v">
                            <v-icon color="primary" class="pr-2">mdi-car</v-icon>Carburant
                            <span class="ml-6">: {{ info.combustion }} </span>
                        </v-list-item-title>
                        <v-list-item-title class="my-1 lst_v">
                            <v-icon color="primary" class="pr-2">mdi-counter</v-icon>Date modèle
                            <span class="ml-5">: 2019</span>
                        </v-list-item-title>

                        <v-list-item-title class="my-1 lst_v">
                            <v-icon color="primary" class="pr-2"> mdi-city</v-icon>
                            ville <span class="ml-16 pl-1">: {{info.companyCity}}</span>
                        </v-list-item-title>

                        <v-list-item-title class="my-1 lst_v">
                            <v-icon color="primary" class="pr-2"> mdi-currency-usd</v-icon>Prix
                            <span class="ml-16 pl-2">: à partir de {{ info.price }} Dhs/Jour</span>
                        </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                  </div>
                    
                    <v-card-actions>
                      <v-row class="justify-content-center">
                        <v-col class="text-center mt-0 mx-auto">
                          <v-btn block large height="40" color="orange" class="white--text" style="text-transform:Capitalize;" depressed @click="showdialog(info)" id="serche_btn">Réserver Maintenant<v-icon right>mdi-arrow-right</v-icon></v-btn>
                        </v-col>
                      </v-row>
                    </v-card-actions>
                  </div>
                  </v-card>
              </v-col>
            <!-- </div> -->
        </v-row>
          </v-col>
          <v-col cols="12" sm="2" >
            <v-card elevation="2" width="100%"  style="padding:10px !important;">
              <v-card-title class="primary--text mb-6" style="justify-content: center">
                <h5>
                  Détail de la rechereche
                </h5>
              </v-card-title>
              <v-combobox v-model="itemSearch.villeD" :items="itemsCity" item-text="name" item-value="id"  label="Ville de départ" id="ville_dr"></v-combobox>
              <v-combobox v-model="itemSearch.villeF" :items="itemsCity" item-text="name" item-value="id"  label="Ville de dépôt" id="ville_dp" ></v-combobox>
              <v-text-field v-model="itemSearch.dateD" label="Date de départ..." type="date"></v-text-field>
              <!-- <v-text-field v-model="itemSearch.timeD" label="Heure de départ..." type="time"></v-text-field> -->
              <v-text-field v-model="itemSearch.dateF" label="Date de dépôt..." type="date"></v-text-field>
              <!-- <v-text-field v-model="itemSearch.timeF" label="Heure de dépôt..." type="time"></v-text-field> -->
              <v-list>
                <v-list-group no-action>
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title >Carburants</v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-item>
                    <v-list-item-content>
                      <v-radio-group v-model="itemSearch.combustion">
                          <v-radio v-for="n in combustions" :key="n" :label="`${n}`" :value="n"></v-radio>
                      </v-radio-group>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
                <v-divider></v-divider>
                 <v-list-group no-action>
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title >Marque</v-list-item-title>
                    </v-list-item-content>
                  </template>

                  <v-list-item>
                    <v-list-item-content>
                      <v-radio-group v-model="itemSearch.marque">
                          <v-radio v-for="n in marques" :key="n" :label="`${n}`" :value="n"></v-radio>
                      </v-radio-group>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
                <v-divider></v-divider>
              </v-list>
              <v-btn block large height="40" color="primary" class="white--text" style="text-transform:Capitalize;" depressed @click="search()" id="serche_btn">Recherche<v-icon right>mdi-search-web</v-icon></v-btn>
            </v-card>
          </v-col>
        </v-row>
      </div>
        <!-- ---------------------------------- dialog ----------------------------- -->
       <v-dialog v-model="mydialogValidation" width="500">
        <v-card>
          <v-toolbar color="orange" height="40" style="position: fixed;width: 500px;z-index: 999;">
            <v-card-title>
              <span style="color:white">location</span>
              <span style="position:fixed;right:0;margin-right:10px;color:white;" @click="closemodal()">X</span>
            </v-card-title>
          </v-toolbar>
          <br><br>
          <v-card-text>
            <v-container>
              votre location bien effectuer 
            </v-container>
          </v-card-text>
        </v-card>
       </v-dialog>
        <!-- ---------------------------------- dialog ----------------------------- -->
    <v-row justify="center">
      <v-dialog v-model="mydialog" max-width="100%" style="max-height=100% !important;margin=0px !important;" transition="dialog-top-transition">
        <v-card>
          <v-toolbar color="orange" style="position: fixed;width: 100%;z-index: 999;">
            <v-card-title>
              <span class="headline white--text">Réservation Voiture : {{ this.getIinfos.desc }}</span>
              <span style="float:left; position:fixed;right:0;margin-right:10px;color:white;" @click="closemodal()">X</span>
            </v-card-title>
          </v-toolbar>
          <br><br>
          <v-card-text>
            <v-container class="px-12">
              <!-- start test -->
              
              <v-bottom-navigation :value="value" color="teal" grow>

                <v-btn @click="changeDesc">
                  <span>Description</span>

                  <v-icon>mdi-history</v-icon>
                </v-btn>
                <v-btn @click="changeImpo">
                  <span>Important</span>

                  <v-icon>mdi-clipboard-list-outline</v-icon>
                </v-btn>
              </v-bottom-navigation>


              <!-- end test -->
              <br>
              <v-row v-if="impo==true">
                <v-list style="width: 100%;">
                <v-list-group no-action :value="true">
                  <template v-slot:activator style="border:1px solid black !important;padding:0px !important;margin:0px !important;">
                    <v-list-item-content style="border:1px solid #5e9fe0 !important;">
                      <v-list-item-title style="color:#5e9fe0;">
                        Important
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-item style="padding:0px !important;">
                    <v-list-item-content style="width: 100%;margin:0px !important;">
                      <v-row>
                        <v-col cols="12" sm="4" style="text-align: left;">
                          <v-icon>mdi-account</v-icon>
                          CONDUCTEUR & PERMIS
                          <br> 
                          <span style="margin-left:30px;font-weight:bold;">
                            EXIGENCES
                          </span>
                        </v-col>
                        <v-col cols="12" sm="8" style="text-align: left;">
                            Lors de la récupération de votre véhicule à l’agence indiquée, vous devez impérativement présenter : <br><br>
                            Votre permis de conduire valide <br>
                            Passeport ou carte d'identité nationale <br>
                            Votre passeport : pour les personnes ne résidant pas au Maroc <br><br>

                            Veuillez noter que : <br> <br>

                            A travers gesloc.ma, vous n’avez pas le droit d’effectuer des réservations pour le compte d’un tiers, comme un membre de votre famille et/ou amis, étant que vous devez présenter vos propres pièces justificatives le jour de récupération du véhicule réservé sur gesloc.ma. En cas de réservation pour le compte d’un tiers, les loueurs de voitures de gesloc.ma se réservent le droit de refuser la prise en charge de votre voiture de location dont vous ne serez jamais remboursés <br><br>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                  
                  <v-divider></v-divider>

                  <v-list-item style="padding:0px !important;">
                    <v-list-item-content style="width: 100%;margin:0px !important;">
                      <v-row>
                        <v-col cols="12" sm="4" style="text-align: left;">
                          <v-icon>mdi-credit-card</v-icon>
                          DÉPÔT DE GARANTIE
                          <br> 
                          <span style="margin-left:30px;font-weight:bold;">
                            5,000 MAD
                          </span>
                        </v-col>
                        <v-col cols="12" sm="8" style="text-align: left;">
                          Au moment de la prise en charge de votre véhicule, vous, étant le conducteur principal, devez laisser un dépôt de garantie remboursable de 5,000 MAD sous forme de : <br><br>

                          Espèces <br><br>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item style="padding:0px !important;">
                    <v-list-item-content style="width: 100%;margin:0px !important;">
                      <v-row>
                        <v-col cols="12" sm="4" style="text-align: left;">
                          <v-icon>mdi-car</v-icon>
                          FRANCHISE DOMMAGES
                          <br> 
                          <span style="margin-left:30px;font-weight:bold;">
                            5,000 MAD
                          </span>
                        </v-col>
                        <v-col cols="12" sm="8" style="text-align: left;">
                          En cas de dommage de votre voiture de location, le montant de votre franchise déterminé selon votre loueur de voiture pour les réparations des dommages. <br><br>
                          La franchise dommage ne couvre pas les autres éléments du véhicule (bris de glace, roues, intérieur, toit ou châssis), les charges (par exemple en cas de remorquage ou de frais d'immobilisation), ou tout ce qui se trouve dans la voiture (par ex. : les sièges enfant, les GPS, vos affaires). <br><br>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>

                  <v-divider></v-divider>

                  <v-list-item style="padding:0px !important;">
                    <v-list-item-content style="width: 100%;margin:0px !important;">
                      <v-row>
                        <v-col cols="12" sm="4" style="text-align: left;">
                          <v-icon>mdi-ev-station</v-icon>
                          POLITIQUE DE CARBURANT
                          <br> 
                          <span style="margin-left:30px;font-weight:bold;">
                            PLEIN À PLEIN
                          </span>
                        </v-col>
                        <v-col cols="12" sm="8" style="text-align: left;">
                            .Au moment de la récupération de votre véhicule, le réservoir de carburant sera plein ou partiellement plein. Vous vous engagerez ainsi de le restituer au même état de carburant initial. A défaut, votre loueur se réserve le droit de vous facturer la différence. <br><br>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>

                  <v-divider></v-divider>

                  <v-list-item style="padding:0px !important;">
                    <v-list-item-content style="width: 100%;margin:0px !important;">
                      <v-row>
                        <v-col cols="12" sm="4" style="text-align: left;">
                          <v-icon>mdi-google-maps</v-icon>
                          KILOMÉTRAGE
                          <br> 
                          <span style="margin-left:30px;font-weight:bold;">
                            ILLIMITÉ
                          </span>
                        </v-col>
                        <v-col cols="12" sm="8" style="text-align: left;">
                            Votre location est plafonnée à illimite KM par jour. Soyez vigilent à ne pas dépasser le kilométrage alloué afin d’éviter tout frais supplémentaire. <br><br>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>

                  <v-divider></v-divider>

                  <v-list-item style="padding:0px !important;">
                    <v-list-item-content style="width: 100%;margin:0px !important;">
                      <v-row>
                        <v-col cols="12" sm="4" style="text-align: left;">
                          <v-icon>mdi-car-wash</v-icon>
                          FRAIS DE NETTOYAGE
                          <br> 
                          <span style="margin-left:30px;font-weight:bold;">
                            300.00 KM
                          </span>
                        </v-col>
                        <v-col cols="12" sm="8" style="text-align: left;">
                            Les frais de nettoyage pour cette location sont plafonnés à 30,00 MAD. <br><br>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>

                  <v-divider></v-divider>

                  <v-list-item style="padding:0px !important;">
                    <v-list-item-content style="width: 100%;margin:0px !important;">
                      <v-row>
                        <v-col cols="12" sm="4" style="text-align: left;">
                          <v-icon>mdi-information</v-icon>
                          INFORMATIONS À RETENIR :
                          <br> 
                          <!-- <span style="margin-left:30px;font-weight:bold;">
                            EXIGENCES
                          </span> -->
                        </v-col>
                        <v-col cols="12" sm="8" style="text-align: left;">
                          gesloc.ma n'assume aucune responsabilité vis-à-vis de ses clients web si les conditions de location ou les conditions générales du partenaire de location de voiture en particulier changent. <br><br>

                          La réservation ne garantit pas une marque ou un modèle de voiture spécifique, mais garantit une catégorie de voiture choisie, à moins qu'il ne soit précisé que le modèle ou la marque de voiture concrète sont garantis par le partenaire de location de voiture. <br><br>

                          Si la catégorie de voiture réservée est disponible au moment de la prise en charge, elle vous sera fournie. A défaut, une voiture de la même ou de meilleure catégorie vous sera affectée. <br><br>

                          Les images des voitures affichées sur la page de recherche sont informatives. La voiture de location fournie lors de la prise en charge peut différer de celle sur la photo. <br><br>

                          L’âge maximal de voitures proposées par nos partenaires de location de voitures ne dépassent pas 3 ans. <br><br>

                          A réservation sur gesloc.ma, vous approuvez que votre âge se situe dans la limite de celui du conducteur spécifié par le partenaire. gesloc.ma ne peut être responsable dans le cas où vous ne répondez pas aux exigences du partenaire de location de voitures. <br><br>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>

                  <v-divider></v-divider>

                </v-list-group>
                <v-list-group no-action>
                  <template v-slot:activator>
                    <v-list-item-content style="border:1px solid #5e9fe0 !important;">
                      <v-list-item-title style="color:#5e9fe0;" >
                        Inclus dans le prix de la location
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-item style="padding:0px !important;">
                    <v-list-item-content style="width: 100%;margin:0px !important;">
                      <v-row style="margin-left:10px !important;">
                       Tous les prix affichés sur gesloc.ma concernent le montant total de la réservation de votre voiture de location et sont affichés en TTC, sauf indication contraire.
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
                    <v-list-group no-action>
                  <template v-slot:activator>
                    <v-list-item-content style="border:1px solid #5e9fe0 !important;">
                      <v-list-item-title style="color:#5e9fe0;" >
                        Non inclus dans le prix de la location
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-item style="padding:0px !important;">
                    <v-list-item-content style="width: 100%;margin:0px !important;">
                      <v-row style="margin-left:10px !important;">
                       Tous les prix de GesLoc ne comprennent ni le montant de la caution ni de la franchise dans la mesure ou elles sont gérées sur place et directement avec votre agence.
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
                <v-list-group no-action>
                  <template v-slot:activator>
                    <v-list-item-content style="border:1px solid #5e9fe0 !important;">
                      <v-list-item-title style="color:#5e9fe0;" >
                        Prérequis pour la prise en charge du véhicule
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-item style="padding:0px !important;">
                    <v-list-item-content style="width: 100%;margin:0px !important;">
                      <v-row style="margin-left:10px !important;text-align:left !important;">
                        Les conditions relatives à chaque agence sont mentionnées et claires avant de procéder à la réservation de ses services. Cependant, en acceptant les conditions de l’agence, vous devez respecter les modalités de paiement à présenter une fois sur place pour éviter toute annulation de la prise en charge de votre demande de location. <br><br>

                        Vous devez, une fois à l’agence et/ou avec l’agent, présenter le code de confirmation de votre réservation afin de procéder à la signature du contrat avec votre loueur. <br><br>

                        A acceptation et signature du contrat, un mail vous sera envoyé systématiquement après que le loueur ait enclenché le code « checkout » qui servira de bonne entente entre le loueur et vous-même. <br><br>

                        A réception du code checkout, vous devez impérativement le communiquer à votre agent avant de récupérer votre voiture de location. <br><br>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
                <v-list-group no-action>
                  <template v-slot:activator>
                    <v-list-item-content style="border:1px solid #5e9fe0 !important;">
                      <v-list-item-title style="color:#5e9fe0;" >
                        Termes d’annulation
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-item style="padding:0px !important;">
                    <v-list-item-content style="width: 100%;margin:0px !important;">
                      <v-row style="margin-left:10px !important;text-align:left !important;">
                        Toute réservation avec offre spéciale ne peut être annulée ni modifiée ni remboursée. <br><br>

                        En cas de réservation sans offre spéciale, vous avez le droit d’annuler à tout moment moyennant des frais d’annulation selon les cas ci-après : <br><br>
                        <span style="margin-left:40px !important;">
                          <v-icon>mdi-arrow-right</v-icon>  En cas d’annulation 72h avant la date et l’heure de votre prise en charge, les frais d’annulation sont de 0% du montant total de la transaction équivalent aux frais de gestion de gesloc.ma <br><br>
                          <v-icon>mdi-arrow-right</v-icon>  En cas d’annulation entre 24h et 72h avant la date et l’heure de votre prise en charge, les frais d’annulation sont de 0% du montant total de la transaction <br><br>
                          <v-icon>mdi-arrow-right</v-icon>  En cas d’annulation 24h avant la date et l’heure de votre prise en charge, les frais d’annulation sont de 0% du montant total de la transaction <br><br>
                        </span>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
                <v-list-group no-action>
                  <template v-slot:activator>
                    <v-list-item-content style="border:1px solid #5e9fe0 !important;">
                      <v-list-item-title style="color:#5e9fe0;" >
                        Termes de remboursement
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-item style="padding:0px !important;">
                    <v-list-item-content style="width: 100%;margin:0px !important;">
                      <v-row style="margin-left:10px !important;text-align:left !important;">
                        Les termes de remboursement sont arrêtés comme-suit : <br><br>
                        <span style="margin-left:40px !important;">
                          <span style="font-weight:bold;"><v-icon>mdi-arrow-right-circle</v-icon>Tout remboursement</span> suite à une annulation client engendrera au minimum 0% des frais de gestion par gesloc.ma (cf. Termes d’annulation) <br><br>
                          <span style="font-weight:bold;"><v-icon>mdi-arrow-right-circle</v-icon>Aucun remboursement</span> ne sera accordé si l'annulation est formulée 24h avant l’heure de prise en charge <br><br>
                          <v-icon>mdi-arrow-right-circle</v-icon>En cas de non présentation après 24h de votre prise en charge, votre réservation sera systématiquement annulée et aucun remboursement ne sera appliqué. <br><br>
                          <span style="font-weight:bold;"><v-icon>mdi-arrow-right-circle</v-icon>Aucun remboursement</span> ne sera appliqué pour les jours de location non utilisés en cas de restitution anticipée <br><br>
                          <span style="font-weight:bold;"><v-icon>mdi-arrow-right-circle</v-icon>Aucun remboursement</span> ne sera appliqué pour les réservations faites moins de 48 heuresavant l'heure de prise en charge <br><br>
                          <span style="font-weight:bold;"><v-icon>mdi-arrow-right-circle</v-icon>Aucun remboursement</span> ne sera appliqué pour les no-show. Au cas où vous n’avez pas l’intention de vous rendre à l’agence ou au lieu de prise en charge, vous devez immédiatement informer les équipes de GesLoc ou annuler votre réservation via votre compte gesloc.ma. Sont considérés des cas de non présentation ou de no-show les cas suivants : <br><br>
                          <div style="margin-left:40px !important;text-align:left !important;">
                            <v-icon>mdi-subdirectory-arrow-right</v-icon>Non-respect de la date et lieu de prise en charge <br><br>
                            <v-icon>mdi-subdirectory-arrow-right</v-icon>Non présentation des documents valables requis à la prise en charge <br><br>
                            <v-icon>mdi-subdirectory-arrow-right</v-icon>Non présentation de mode de paiement acceptable par le partenaire de location de voiture <br><br>
                          </div>
                        </span>
                      </v-row>
                      <v-row style="margin-left:10px !important;text-align:left !important;">
                        Veuillez noter que : <br> <br>
                        Les termes de remboursement sont arrêtés comme-suit :
                        <span style="margin:40px;">
                          <v-icon>mdi-arrow-right-circle</v-icon>Non présentation. <br>
                          <v-icon>mdi-arrow-right-circle</v-icon>Présentation en retard. <br>
                          <v-icon>mdi-arrow-right-circle</v-icon>Jours non utilisés. <br>
                          <v-icon>mdi-arrow-right-circle</v-icon>Absence d’un document cité au préalable. <br>
                          <v-icon>mdi-arrow-right-circle</v-icon>Non-respect d’une ou plusieurs clauses du contrat de gesloc.ma et/ou des ses partenaires de location de voitures. <br>
                          <v-icon>mdi-arrow-right-circle</v-icon>Blacklisting par un autre partenaire de location de voiture. <br>
                          <v-icon>mdi-arrow-right-circle</v-icon>Etat d’ivresse/drogue.
                        </span>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
                <v-list-group no-action>
                  <template v-slot:activator>
                    <v-list-item-content style="border:1px solid #5e9fe0 !important;">
                      <v-list-item-title style="color:#5e9fe0;" >
                        Termes de modification
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-item style="padding:0px !important;">
                    <v-list-item-content style="width: 100%;margin:0px !important;">
                      <v-row style="margin-left:10px !important;text-align:left !important;">
                        Les modifications des réservations ne sont pas autorisées sur gesloc.ma. <br><br>

                        En cas de prolongation de votre durée et/ou modification des lieux de prise en charge et/ou de restitution, nous vous invitons à prendre attache directement avec votre agence indiquée. <br><br>
                        <span style="margin:0px 35px 0px 10px !important;background-color:#ff980054;padding:5px !important;">
                          Veuillez noter que : <br><br>
                          <div style="margin-left:10px !important;">
                             <v-icon>mdi-arrow-right-circle</v-icon>En cas de prolongation de votre durée de réservation au moment de la prise en charge de voiture, le partenaire de location de voitures vous facturera les tarifs locaux en vigueur. <br><br>
                             <v-icon>mdi-arrow-right-circle</v-icon>Par ailleurs, en cas de problème rencontré au-delà de la durée contractualisée sur GesLoc, GesLoc se désengage de toute responsabilité et n’interviendra aucunement entre vous deux. <br><br>
                          </div>
                        </span>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
                <v-list-group no-action>
                  <template v-slot:activator>
                    <v-list-item-content style="border:1px solid #5e9fe0 !important;">
                      <v-list-item-title style="color:#5e9fe0;" >
                        gesloc.ma  
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-item style="padding:0px !important;">
                    <v-list-item-content style="width: 100%;margin:0px !important;">
                      <v-row style="margin-left:10px !important;text-align:left !important;">
                        <div style="font-weight:bold;">A propos de gesloc.ma <br><br></div>
                         <div><v-icon>mdi-arrow-right-circle</v-icon>gesloc.ma est une plateforme de réservation vous permettant de comparer en un clic toutes les offres de location de voitures dans les grandes et les petites villes du Maroc. <br><br></div>
                        <div><v-icon>mdi-arrow-right-circle</v-icon>La mission de gesloc.ma est de vous aider à trouver la voiture la mieux adaptée à vos besoins de la manière la plus efficace et la plus efficiente possible. <br><br></div>
                        <div><v-icon>mdi-arrow-right-circle</v-icon>Avec son puissant système de gestion incubant l’ensemble des agences partenaires, gesloc.ma est aujourd’hui en ligne avec les standards internationaux pour vous livrer, en un simple clic, tous les résultats en temps réel, parfaitement comparables pour un meilleur rapport qualité/prix. <br><br></div>
                        <div><v-icon>mdi-arrow-right-circle</v-icon>De par son cycle de formation et de conduite du changement customisé à ses partenaires, gesloc.ma s’assure de la standardisation et l’harmonisation des méthodes de travail afin de répondre au mieux au besoin de sa clientèle internationale. <br><br></div>
                        <div style="font-weight:bold;"><br>Comment fonctionne gesloc.ma ?<br><br></div> 
                        <div><v-icon>mdi-arrow-right-circle</v-icon>gesloc.ma est garante du bon déroulement du processus de réservation du paiement de la réservation en ligne par ses clients jusqu’à la prise en charge de leur demande par ses partenaires. <br><br></div>
                        <div><v-icon>mdi-arrow-right-circle</v-icon>Le paiement est sécurisé et fait directement sur gesloc.ma afin de garantir la disponibilité du véhicule réservé le jour J. <br><br></div>
                        <div><v-icon>mdi-arrow-right-circle</v-icon>A paiement de la réservation, le client reçoit un mail de confirmation récapitulant les détails de sa réservation en spécifiant un code de réservation à présenter le jour J de la prise en charge de sa demande. <br><br></div>
                        <div><v-icon>mdi-arrow-right-circle</v-icon>A récupération du véhicule, le client doit s’assurer que la voiture « similaire » correspond parfaitement à la catégorie de voitures de gesloc.ma. En cas de voiture « similaire » et/ou « upgrade », le client reçoit un mail enclenché systématiquement par le loueur comprenant un code « check-out » à communiquer au moment de la signature du contrat. Par ailleurs, en cas de « sous-classement » et/ou d’indisponibilité de voiture, le client doit immédiatement contacter gesloc.ma pour intervention imminente. <br><br></div>
                        <div style="font-weight:bold;">Est-ce que gesloc.ma est gratuite ?</div> <br><br>
                        <div><v-icon>mdi-arrow-right-circle</v-icon>Afin de garantir un prix attractif à ses clients, gesloc.ma permet aux agences de location de voitures à exposer leur flotte à l’international pour mieux faire connaître leurs offres. <br><br></div>
                        <div style="width: 100%;"><v-icon>mdi-arrow-right-circle</v-icon>La rémunération de gesloc.ma se fait directement par ses partenaires pour la digitalisation de leur processus de location de voitures. <br></div>
                        <div style="font-weight:bold;"><br>Pourquoi gesloc.ma ?<br><br></div> 
                        <div><v-icon>mdi-arrow-right-circle</v-icon>gesloc.ma, concept mise en œuvre pour la structuration et la digitalisation du marché des loueurs de voitures au Maroc et bientôt dans les 54 pays d’Afrique. L’idée étant de contribuer au développement du tissu local en leur offrant les prérequis technologiques leur facilitant l’accès à la centrale de réservation gesloc.ma. <br><br></div>
                      </v-row>
                      <br><br>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
                </v-list>
              </v-row>

              <v-row v-if="desc==true">
                <v-col cols="12" sm="6">
                  <v-row style="margin-top: 0px; border-radius:5px;">
                    <v-col sm="12">
                      <div class="p-2" style="background-color: rgba(245,245,245); border-radius:5px;">
                          <label class="mt-2" style="font-size: 25px; font-weight: bold; color: #4675db">INFORMATIONS SUR LA VOITURE</label>
                      </div>
                      <img :src="getIinfos.image" class="white--text align-end mt-4" height="235px" width="100%">
                        <v-simple-table>
                          <template v-slot:default>
                            <thead>
                            </thead>
                            <tbody>
                              <tr>
                                <td style="font-weight:bold;font-size:17px;padding:0px;float:left;">Marque</td>
                                <td>{{ getIinfos.marque }}</td>
                              </tr>
                              <tr>
                                <td style="font-weight:bold;font-size:17px;padding:0px;float:left;">Modèle</td>
                                <td>{{ getIinfos.desc }}</td>
                              </tr>
                              <tr>
                                <td style="font-weight:bold;font-size:17px;padding:0px;float:left;">Date modèle</td>
                                <td>{{ getIinfos.date }}</td>
                              </tr>
                              <tr>
                                <td style="font-weight:bold;font-size:17px;padding:0px;float:left;">Carburants</td>
                                <td>{{ getIinfos.combustion }}</td>
                              </tr> 
                              <tr>
                                <td style="font-weight:bold;font-size:17px;padding:0px;float:left;">Kilométrage</td>
                                <td>{{ getIinfos.km }} Km</td>
                              </tr>
                              <tr>
                                <td style="font-weight:bold;font-size:17px;padding:0px;float:left;">prix par jour  </td>
                                <td>{{ getIinfos.price }} Dhs</td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                        <!-- <div class="bg-info p-1 mb-1">
                         <label for="" style="font-size: 22px; font-weight: bold; color: white" class="mt-2">ACCESSOIRES & ASSURANCE</label> <br>
                        </div>
                         <label disabled for="" style="font-size: 20px; font-weight: normal; color: #4675db" class="">Accessoires</label>
                         <v-divider></v-divider>
                        <v-simple-table>
                          <template v-slot:default>
                          <tbody>
                            <tr>
                              <td> <v-checkbox disabled label="GPS navigateur" value="GPS navigateur" color="orange darken-3">
                                </v-checkbox>
                              </td>
                              <td>
                                <span>50 Dhs/J</span>
                              </td>
                            </tr>
                            <tr>
                              <td> <v-checkbox disabled label="nourrisson siège enfant -Sièges déterminé par le poids / enfant d'âge" value="nourrisson" color="orange darken-3">
                                </v-checkbox>
                              </td>
                              <td>
                                <span>50 Dhs/J</span>
                              </td>
                            </tr>
                            <tr>
                              <td> <v-checkbox disabled label="Poussette de bébé" value="Poussette de bébé" color="orange darken-3">
                                </v-checkbox>
                              </td>
                              <td>
                                <span>50 Dhs/J</span>
                              </td>
                            </tr>
                          </tbody>
                          </template>
                        </v-simple-table>
                         <label for="" style="font-size: 20px; font-weight: normal; color: #4675db" class="text-left">Assurance</label>
                           <v-simple-table>
                          <template v-slot:default>
                          <tbody>
                            <tr>
                              <td> <v-checkbox disabled label="Assurance responsabilité civile" value="Assurance responsabilité civile" color="orange darken-3">
                                </v-checkbox>
                              </td>
                              <td>
                                <span>50 Dhs/J</span>
                              </td>
                            </tr>
                            <tr>
                              <td> <v-checkbox disabled label="Couverture complet" value="Couverture complet" color="orange darken-3">
                                </v-checkbox>
                              </td>
                              <td>
                                <span>50 Dhs/J</span>
                              </td>
                            </tr>
                          </tbody>
                          </template>
                        </v-simple-table> -->
                       
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-row class="mt-3">
                    <v-col sm="12" style="background-color: rgba(245,245,245); border-radius:5px;" class="p-2">
                      <label class="mt-2" style="font-size: 25px; font-weight:bold; color:#4675db">VOS INFORMATIONS</label>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field label="Nom" required v-model="itemsClient.nom"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field label="Prénom" required v-model="itemsClient.prenom"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field style="margin-top: -15px" label="Téléphone" hint="exemple: 0612121212" required v-model="itemsClient.tele"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field style="margin-top: -15px" label="CIN" v-model="itemsClient.cin"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field style="margin-top: -15px" label="Permis" required v-model="itemsClient.permis"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field style="margin-top: -15px" label="Adresse" v-model="itemsClient.adresse"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field style="margin-top: -15px" label="Email" required v-model="itemsClient.email" ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="6">
                      <v-text-field style="margin-top: -15px" type="date" label="Date Départ" v-model="itemSearch.dateD" required disabled></v-text-field>
                    </v-col>
                    <v-col sm="12" md="6">
                      <v-text-field style="margin-top: -15px" id="heur_dp_md" label="Heure de depart" v-model="itemSearch.timeD" disabled ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="6">
                      <v-text-field style="margin-top: -15px" type="date" label="Date de Retour" v-model="itemSearch.dateF" required disabled></v-text-field>
                    </v-col>
                    <v-col sm="12" md="6" class="mt-0">
                      <v-text-field style="margin-top: -15px" id="heur_dp_md" label="Heure de Retour" v-model="itemSearch.timeF" disabled ></v-text-field>
                    </v-col>
                     <v-col sm="12" md="6">
                      <v-text-field style="margin-top: -15px"  label="Ville de Départ" v-model="itemSearch.villeD" required disabled></v-text-field>
                    </v-col>
                    <v-col sm="12" md="6" class="mt-0">
                      <v-text-field style="margin-top: -15px" id="heur_dp_md" label="ville de Dépôt" v-model="itemSearch.villeF" disabled ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12" class="" style="">
                      <!-- <div class="bg-info p-1 mb-1 pt-1">
                        <label style="font-size: 22px; font-weight: bold; color: white" class="text-start mt-1">OPTION DE PAIEMENT</label>
                      </div> -->
                          <!-- <v-simple-table readonly >
                          <template v-slot:default>
                          <tbody>
                            <tr>
                              <td>  
                                <v-radio-group v-model="radiogroup" disabled>
                                  <v-radio label="Paiement par PayPal" value="paypal" color="orange darken-4" >
                                  </v-radio>
                                </v-radio-group>
                              </td>
                              <td>
                                <v-img src="../assets/paypal.png" width="60px" height="25px" class="py-img"></v-img>
                              </td>
                            </tr>
                             <tr>
                              <td >  
                                <v-radio-group v-model="radiogroup" disabled>
                                  <v-radio label="Paiement par carte bancaire" value="visa" color="orange darken-4" >
                                  </v-radio>
                                </v-radio-group>
                              </td>
                              <td>
                                <v-img src="../assets/visa.png" width="60px" height="25px" class="py-img"></v-img>
                              </td>
                            </tr>
                          </tbody>
                          </template>
                          </v-simple-table> -->
                            <!-- --------------- paypal form --------------------- -->
                            <!-- <v-row>
                            <v-col cols="12">
                            <v-card class="m-0" elevation="2" width="100%" disabled>
                              <v-card-title class="bg-info white--text">Remplire les informations</v-card-title>
                              <v-card-text>
                                <v-text-field label="PayPal email" required></v-text-field>
                              </v-card-text>
                            </v-card>
                            </v-col>
                            </v-row> -->
                              <!-- ----------------- visa form ------------------- -->
                            <!-- <v-row>
                            <v-col cols="12">
                            <v-card class="m-0" elevation="2" width="100%" disabled>
                              <v-card-title class="bg-info white--text">Remplire les informations</v-card-title>
                              <v-card-text>
                                <v-text-field label="Nom du titulaire" required></v-text-field>
                                <v-text-field label="Numéro de carte" type="number" required counter="24" :rules="cardrules"></v-text-field>
                                
                              </v-card-text>
                            </v-card>
                            </v-col>
                            </v-row> -->
                    </v-col>
                    <v-col cols="12">
                        <v-checkbox :rules="[v=>!!v || 'obligatoire']" v-model="chekcbox" label="Je confirme que je l'ai lu et accepté les règles et conditions de réservation." required>
                        </v-checkbox>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
                <v-card-actions class="my-5" v-if="desc==true">
                  <v-spacer></v-spacer>
                  <v-btn color="red" class="white--text" light large @click="mydialog = false">Annuler<v-icon right>mdi-close</v-icon></v-btn>
                  <v-btn color="green" class="white--text" light large @click="validate_dlg()">Valider<v-icon right>mdi-check-underline</v-icon></v-btn>
                </v-card-actions>
            </v-container>
            <!-- <small>*indicates required field</small> -->
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    </div>
  </div>
</template>

<style lang="scss">
// .v-image__image--cover
.v-dialog:not(.v-dialog--fullscreen){
  max-height:94% !important;
  margin: 150px 0px 60px 0px  !important;
}
.vcard{
background: rgb(255,255,255);
background: linear-gradient(0deg, rgba(248, 248, 248, 0.904) 0%, rgba(248, 248, 248, 0.905) 100%),url("../assets/bg-lined.png");
background-repeat: repeat;
background-size: contain;
}

#form_rm{
background: #fdfdfd;
}

.lst_v{
  font-size:13px;
}

.crd_effect{
  transition: 0.3s ease-in-out;
}
.crd_effect:hover{
  transform: scale(1.03);
}

.v-list-item{
  display: block !important;
}
@media (max-width: 380px) {
  #top_h1{
    font-size: 50px;
    color: red;
  }
  .top_p {
    font-size: 15px;
  }
}

// .bounce-enter-from{
//   opacity:0;
// }
// .bounce-enter-to{
//   opacity:1;
// }
// .bounce-enter-active{
//   animation:opacity .2s 
// }
.v-image__image--cover{
background-size:100% 100% !important;
}

</style>

<script>
import axios from "axios";
// import gsap from 'gsap';

export default {
  data() {
    //  beforeEnter=(el)=>{
    //   el.style.tranform='translateY(-40px)'
    //   el.style.opacity=0
    // }
    //  enter=(el)=>{
    //   gsap.to(el,{
    //     duration:1,
    //     y:0,
    //     opacity:1
    //   })      
    // }
    return {
      animation:true,
      impo:false,
      desc:true,
      value: 1,
      itemSearch: {},
      itemsClient: {},
      toggleMenu: false,
      menu1:false,
      menu2:false,
      links: ["Accueil", "About Us", "Team", "Services", "Blog", "Contact Us"],
      itemsCity: [],
      marques: [
        "Tout",
        "Alfa Romeo",
        "Audi",
        "BMW",
        "Citroën",
        "Dacia",
        "Fiat",
        "Ford",
        "Mercedes",
        "Nissan",
        "Opel",
        "Peugeot",
        "Renault",
        "Toyota",
        "Seat",
        "Skoda",
        "Hyundai",
        "Jeep",
        "Land Rover",
        "Mini",
        "Honda",
        "Mazda",
        "Chevrolet",
        "Kia",
        "Lexus",
        "Volkswagen",
      ],
      combustions: ["Tout", "Diesel", "Essence", "Hybride", "Electrique"],
      modeles: ["2015", "2016", "2017", "2018", "2019", "2020", "2021"],
      icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
      mydialog: false,
      mydialogValidation:false,
      info:{},
      infos: [],
      getIinfos: [],
      clientData: [],
      menu: [
        { title: "title1", link: "#", icon: "mdi-plus" },
        { title: "title2", link: "#", icon: "mdi-plus" },
        { title: "title3", link: "#", icon: "mdi-plus" },
      ],
      display: 3,
      image:'',
      radiogroup:'',
      radios:[
        {label:"Paiement par PayPal",value:"Paiement par PayPal",color:"orange darken-3",icon:"paypal.png"},
        {label:"Paiement par carte bancaire",value:"Paiement par carte bancaire",color:"orange darken-3",icon:"visa.png"}
        ],
        showpy:false,
        cardrules:[value => value.length=24 || 'Devrait comporter 24 caractères']
    };
  },
  // computed: {
  //   showMenu() {
  //     return this.toggleMenu || this.$vuetify.breakpoint.mdAndUp;
  //   }, ======================================================= WHAT NEXT ASSAT ============
  // },
  mounted() {
    // axios
    //   .get(
    //     "https://octobot-consulting.com/demo/locationVoiture/api/rents/availableCar"
    //   )
    //   .then((Response) => {
    //     this.infos = Response.data.cars[0];
    //     console.log(this.infos);
    //   })
    //   .catch((erreur) => console.log(erreur));itemsCity
      this.mydialog=false

      this.today=new Date()
      this.itemSearch.dateD=this.today.toISOString().slice(0,10)
      this.itemSearch.timeD=new Date().getHours() + ":" + new Date().getMinutes()
      this.itemSearch.dateF=new Date(this.today.getTime() + 86400000).toISOString().slice(0,10)
      this.itemSearch.timeF=new Date().getHours() + ":" + new Date().getMinutes()
    //  axios.get("https://gesloc.ma/locationVoiture/api/city").then(ResponseCity=>{
    //    this.itemsCity=ResponseCity.data
    //  })
    //  axios.get("https://gesloc.ma/locationVoiture/api/rents/availableCar")
    //   .then((ResponseDemo) => {
    //     ResponseDemo.data.cars[0].forEach(Cars => {
    //       this.info={
    //         idDemo:Cars.id,
    //         marque:Cars.marque,
    //         desc:Cars.desc,
    //         price:Cars.price,
    //         km:Cars.km,
    //         date:Cars.date_mec,
    //         combustion:Cars.combustion,
    //         fiscal_power:Cars.fiscal_power,
    //         image:'https://gesloc.ma/locationVoiture/'+Cars.file,
    //         companyName:ResponseDemo.data.company.name,
    //         companyLogo:'https://gesloc.ma/locationVoiture/'+ResponseDemo.data.company.logo,
    //         companyCity:ResponseDemo.data.company.city
    //       }
    //       this.infos.push(this.info)
    //     });
    //   }).catch((erreur) => console.log(erreur));

      axios.get("https://gesloc.ma/moneycar/api/rents/availableCar")
      .then((ResponseMoney) => {
        ResponseMoney.data.cars[0].forEach(Cars => {
          this.info={
            idMoney:Cars.id,
            marque:Cars.marque,
            desc:Cars.desc,
            price:Cars.price,
            km:Cars.km,
            date:Cars.date_mec,
            combustion:Cars.combustion,
            fiscal_power:Cars.fiscal_power,
            image:'https://gesloc.ma/moneycar/'+Cars.file,
            companyName:ResponseMoney.data.company.name,
            companyLogo:'https://gesloc.ma/moneycar/'+ResponseMoney.data.company.logo,
            companyCity:ResponseMoney.data.company.city
          }
          this.infos.push(this.info)
        });
      })
      .catch((erreur) => console.log(erreur));

      axios.get("https://gesloc.ma/janarent/api/rents/availableCar")
      .then((ResponseJana) => {
        ResponseJana.data.cars[0].forEach(Cars => {
          this.info={
            idJana:Cars.id,
            marque:Cars.marque,
            desc:Cars.desc,
            price:Cars.price,
            km:Cars.km,
            date:Cars.date_mec,
            combustion:Cars.combustion,
            fiscal_power:Cars.fiscal_power,
            image:'https://gesloc.ma/janarent/'+Cars.file,
            companyName:ResponseJana.data.company.name,
            companyLogo:'https://gesloc.ma/janarent/'+ResponseJana.data.company.logo,
            companyCity:ResponseJana.data.company.city
          }
          this.infos.push(this.info)
        });
      }).catch((erreur) => console.log(erreur));
  },
  methods: {
    onResize() {
      this.display = 2;
    },
    
    showdialog(item) {
      if(this.itemSearch.villeD=='' || this.itemSearch.villeD==null){
        this.itemSearch.villeD=item.companyCity
        this.itemSearch.villeF=item.companyCity
      }else{
        this.itemSearch.villeD=this.itemSearch.villeD.name
        this.itemSearch.villeF=this.itemSearch.villeF.name
      }
      this.getIinfos = item;
      this.mydialog = true;
    },

    search() {
      this.info={}
      this.infos = [];
      if (!this.itemSearch.marque || this.itemSearch.marque == "") {
        this.itemSearch.marque = "Tout";
      }
      if (!this.itemSearch.combustion || this.itemSearch.combustion == "") {
        this.itemSearch.combustion = "Tout";
      }
      if (!this.itemSearch.villeD.name || this.itemSearch.villeD.name == "") {
        this.itemSearch.villeD.name = "none";
      }
      if (!this.itemSearch.villeF.name || this.itemSearch.villeF.name == "") {
        this.itemSearch.villeF.name = "none";
      }
      axios.post("https://gesloc.ma/locationVoiture/api/rents/FilteravailableCarOnline",{date1: this.itemSearch.dateD,date2: this.itemSearch.dateF,marque: this.itemSearch.marque,combustion: this.itemSearch.combustion,city1:this.itemSearch.villeD.name,city2:this.itemSearch.villeF.name})
        .then((ResponseDemo) => {
          ResponseDemo.data.cars[0].forEach(Cars => {
          this.info={
            idDemo:Cars.id,
            marque:Cars.marque,
            desc:Cars.desc,
            price:Cars.price,
            km:Cars.km,
            date:Cars.date_mec,
            combustion:Cars.combustion,
            fiscal_power:Cars.fiscal_power,
            image:'https://gesloc.ma/locationVoiture/'+Cars.file,
            companyName:ResponseDemo.data.company.name,
            companyLogo:'https://gesloc.ma/locationVoiture/'+ResponseDemo.data.company.logo,
            companyCity:ResponseDemo.data.company.city
          }
          this.infos.push(this.info)
        });
        }).catch((erreur) => console.log(erreur));

        // axios.post("https://gesloc.ma/lunicaCar/api/rents/FilteravailableCarOnline",{date1: this.itemSearch.dateD,date2: this.itemSearch.dateF,marque: this.itemSearch.marque,combustion: this.itemSearch.combustion,city1:this.itemSearch.villeD.name,city2:this.itemSearch.villeF.name})
        // .then((ResponseDemo) => {
        //   ResponseDemo.data.cars[0].forEach(Cars => {
        //   this.info={
        //     idLunica:Cars.id,
        //     marque:Cars.marque,
        //     desc:Cars.desc,
        //     price:Cars.price,
        //     km:Cars.km,
        //     date:Cars.date_mec,
        //     combustion:Cars.combustion,
        //     fiscal_power:Cars.fiscal_power,
        //     image:'https://gesloc.ma/lunicaCar/'+Cars.file,
        //     companyName:ResponseDemo.data.company.name,
        //     companyLogo:'https://gesloc.ma/lunicaCar/'+ResponseDemo.data.company.logo,
        //     companyCity:ResponseDemo.data.company.city
        //   }
        //   this.infos.push(this.info)
        // });
        // }).catch((erreur) => console.log(erreur));

        axios.post("https://gesloc.ma/janarent/api/rents/FilteravailableCarOnline",{date1: this.itemSearch.dateD,date2: this.itemSearch.dateF,marque: this.itemSearch.marque,combustion: this.itemSearch.combustion,city1:this.itemSearch.villeD.name,city2:this.itemSearch.villeF.name})
        .then((ResponseJana) => {
          ResponseJana.data.cars[0].forEach(Cars => {
          this.info={
            idJana:Cars.id,
            marque:Cars.marque,
            desc:Cars.desc,
            price:Cars.price,
            km:Cars.km,
            date:Cars.date_mec,
            combustion:Cars.combustion,
            fiscal_power:Cars.fiscal_power,
            image:'https://gesloc.ma/janarent/'+Cars.file,
            companyName:ResponseJana.data.company.name,
            companyLogo:'https://gesloc.ma/janarent/'+ResponseJana.data.company.logo,
            companyCity:ResponseJana.data.company.city
          }
          this.infos.push(this.info)
        });
        }).catch((erreur) => console.log(erreur));

        axios.post("https://gesloc.ma/moneycar/api/rents/FilteravailableCarOnline",{date1: this.itemSearch.dateD,date2: this.itemSearch.dateF,marque: this.itemSearch.marque,combustion: this.itemSearch.combustion,city1:this.itemSearch.villeD.name,city2:this.itemSearch.villeF.name})
        .then((ResponseMoney) => {
          ResponseMoney.data.cars[0].forEach(Cars => {
          this.info={
            idMoney:Cars.id,
            marque:Cars.marque,
            desc:Cars.desc,
            price:Cars.price,
            km:Cars.km,
            date:Cars.date_mec,
            combustion:Cars.combustion,
            fiscal_power:Cars.fiscal_power,
            image:'https://gesloc.ma/moneycar/'+Cars.file,
            companyName:ResponseMoney.data.company.name,
            companyLogo:'https://gesloc.ma/moneycar/'+ResponseMoney.data.company.logo,
            companyCity:ResponseMoney.data.company.city
          }
          this.infos.push(this.info)
        });
        }).catch((erreur) => console.log(erreur));
    },

    closemodal(){
      this.mydialog=false;
      this.mydialogValidation=false
    },

    changeDesc(){
      this.desc=true
      this.impo=false
    },

    changeImpo(){
      this.impo=true
      this.desc=false
    },

    validate_dlg() {
      // if(this.getIinfos.idLunica){
      //   axios.post("https://gesloc.ma/lunicaCar/api/booking",
      //     {
      //       firstname_client: this.itemsClient.nom,
      //       lastname_client: this.itemsClient.prenom,
      //       phone_client: this.itemsClient.tele,
      //       cin_client: this.itemsClient.cin,
      //       address_client: this.itemsClient.adresse,
      //       permis_client: this.itemsClient.permis,
      //       startdate_rent: this.itemSearch.dateD,
      //       starttime_rent: this.itemSearch.timeD,
      //       findate_rent: this.itemSearch.dateF,
      //       fintime_rent: this.itemSearch.timeF,
      //       car_id: this.getIinfos.idDemo,
      //       is_validated:0,
      //       city1:this.itemSearch.villeD,
      //       city2:this.itemSearch.villeF,
      //       price:this.getIinfos.price
      //     })
      //   .then((ResponseData) => {
      //     console.log(ResponseData.data);
      //     // this.itemSearch={}
      //     // this.itemsClient={}
      //     this.mydialog = false;
      //   });
      //     this.itemClient={
      //       "cin":this.itemsClient.cin,
      //       "permis":this.itemsClient.permis,
      //       "first_name":this.itemsClient.nom,
      //       "last_name":this.itemsClient.prenom,
      //       "email":'test',
      //       "phone":this.itemsClient.tele,
      //       "address":this.itemsClient.adresse,
      //     }
      //     axios.post('http://localhost:8000/api/clients',this.itemClient).then(ResponseClient=>{
      //       console.log(ResponseClient.data)
      //     })
      // }   
      // lunicaCar
      if(this.getIinfos.idDemo){
        axios.post("https://gesloc.ma/locationVoiture/api/booking",
          {
            firstname_client: this.itemsClient.nom,
            lastname_client: this.itemsClient.prenom,
            phone_client: this.itemsClient.tele,
            cin_client: this.itemsClient.cin,
            address_client: this.itemsClient.adresse,
            permis_client: this.itemsClient.permis,
            startdate_rent: this.itemSearch.dateD,
            starttime_rent: this.itemSearch.timeD,
            findate_rent: this.itemSearch.dateF,
            fintime_rent: this.itemSearch.timeF,
            car_id: this.getIinfos.idDemo,
            is_validated:0,
            city1:this.itemSearch.villeD,
            city2:this.itemSearch.villeF,
            price:this.getIinfos.price
          })
        .then((ResponseData) => {
          console.log(ResponseData.data);
          // this.itemSearch={}
          // this.itemsClient={}
          this.mydialog = false;
          this.mydialogValidation=true
        });
          this.itemClient={
            "cin":this.itemsClient.cin,
            "permis":this.itemsClient.permis,
            "first_name":this.itemsClient.nom,
            "last_name":this.itemsClient.prenom,
            "email":'test',
            "phone":this.itemsClient.tele,
            "address":this.itemsClient.adresse,
          }
          axios.post('http://localhost:8000/api/clients',this.itemClient).then(ResponseClient=>{
            console.log(ResponseClient.data)
          })

      }   
      if (this.getIinfos.idMoney) {
         axios.post("https://gesloc.ma/moneycar/api/booking",
          {
            firstname_client: this.itemsClient.nom,
            lastname_client: this.itemsClient.prenom,
            phone_client: this.itemsClient.tele,
            cin_client: this.itemsClient.cin,
            address_client: this.itemsClient.adresse,
            permis_client: this.itemsClient.permis,
            car_id: this.getIinfos.idMoney,
            startdate_rent: this.itemSearch.dateD,
            starttime_rent: this.itemSearch.timeD,
            findate_rent: this.itemSearch.dateF,
            fintime_rent: this.itemSearch.timeF,
            is_validated:0,
            city1:this.itemSearch.villeD,
            city2:this.itemSearch.villeF
          })
        .then((ResponseData) => {
          console.log(ResponseData.data);
          // this.itemSearch={}
            // this.itemsClient={}
            this.mydialog = false;
            this.mydialogValidation=true
          });
           this.itemClient={
            "cin":this.itemsClient.cin,
            "permis":this.itemsClient.permis,
            "first_name":this.itemsClient.nom,
            "last_name":this.itemsClient.prenom,
            "email":'test',
            "phone":this.itemsClient.tele,
            "address":this.itemsClient.adresse,
          }
          axios.post('http://localhost:8000/api/clients',this.itemClient).then(ResponseClient=>{
            console.log(ResponseClient.data)
          })
      }

      if (this.getIinfos.idJana) {
         axios.post("https://gesloc.ma/janarent/api/booking",
          {
            firstname_client: this.itemsClient.nom,
            lastname_client: this.itemsClient.prenom,
            phone_client: this.itemsClient.tele,
            cin_client: this.itemsClient.cin,
            address_client: this.itemsClient.adresse,
            permis_client: this.itemsClient.permis,
            car_id: this.getIinfos.idJana,
            startdate_rent: this.itemSearch.dateD,
            starttime_rent: this.itemSearch.timeD,
            findate_rent: this.itemSearch.dateF,
            fintime_rent: this.itemSearch.timeF,
            is_validated:0,
            city1:this.itemSearch.villeD,
            city2:this.itemSearch.villeF
          })
        .then((ResponseData) => {
          console.log(ResponseData.data);
          // this.itemSearch={}
          // this.itemsClient={}
          this.mydialog = false;
          this.mydialogValidation=true
        });
         this.itemClient={
            "cin":this.itemsClient.cin,
            "permis":this.itemsClient.permis,
            "first_name":this.itemsClient.nom,
            "last_name":this.itemsClient.prenom,
            "email":'test',
            "phone":this.itemsClient.tele,
            "address":this.itemsClient.adresse,
          }
          axios.post('http://localhost:8000/api/clients',this.itemClient).then(ResponseClient=>{
            console.log(ResponseClient.data)
          })
      }
    },
  },
}
</script>