<template>
    <div>
        <div class="top_side" id="contact_rm">
            <div class='text-bg'>
                <p class="display-3 orange--text" style="font-weight:bold;">Connexion</p>
                <p class="subtitle white--text">additionnel description de cette page</p>
            </div>
        </div>
        <div class="container px-auto mx-auto" v-if="testLogin==false">
            <div class="px-auto my-15 mx-auto">
                <v-card elevation="3" align="center" class="content-bg py-15 px-auto mx-auto">
                    <div class="content mx-auto justify-content-center">
                        <v-card-title>
                            <v-col sm="12" justify="center">
                                <h1 class="">Login</h1>
                            </v-col>
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field label="numéro de tél" v-model="itemLogin.phone" required>
                                    </v-text-field>
                                </v-col>
                            </v-row> 
                            <v-row>
                                <v-col cols="12" >
                                    <v-text-field label="CIN" v-model="itemLogin.cin" type="password" required>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions class="my-3">
                            <v-col sm="12">
                                <v-btn color="orange" large block outlined class="white--text" @click="Login()"><v-icon dark left>mdi-send</v-icon>Connexion </v-btn>
                            </v-col>
                        </v-card-actions>
                    </div>
                </v-card>
            </div>
        </div>
        <div v-if="testLogin==true">
       
       <v-toolbar>
            <template v-slot:extension>
                <v-tabs v-model="tab" align-with-title>
                <v-tabs-slider color="yellow"></v-tabs-slider>

                <v-tab>Mes Commandes</v-tab>
                <v-tab>Mes Informations</v-tab>
                <v-tab>Les Condition Generale </v-tab>
                </v-tabs>
            </template>
        </v-toolbar>

        <v-tabs-items v-model="tab">
            <v-tab-item>
                <v-card flat>
                        <v-card-text>
                          Mes Commandes
                        </v-card-text>
                        <v-data-table :headers="headers" :search="search" :items="desserts" sort-by="name" class="elevation-1">
                            <template v-slot:[`item.is_validated`]="{ item }">
                                <span v-if="item.is_validated==1" style="color:green !important;">Valider</span>
                                <span v-if="item.is_validated==0" style="color:red !important;">En Cours</span>
                            </template>
                             <template v-slot:[`item.actions`]="{ item }">
                                <v-icon small color="blue" class="mr-2" @click="showItem(item)">mdi-message-text</v-icon>
                                <v-icon small color="red" @click="deleteItem(item)">mdi-delete</v-icon>
                            </template>
                        </v-data-table>
                        <v-dialog v-model="dialogDetails" width="500">
                            <v-card>
                                <v-card-title class="text-h5 grey lighten-2">
                                Privacy Policy
                                </v-card-title>

                                <v-card-text>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                </v-card-text>

                                <v-divider></v-divider>

                                <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="dialogDetails = false">I accept</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                         <v-dialog v-model="dialogDelete" width="500">
                            <v-card>
                                <v-card-title class="text-h5 red lighten-2">
                                    Annulation
                                </v-card-title>

                                <v-card-text>
                                    Êtes-vous sûr de annuler cet location ?
                                </v-card-text>

                                <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="dialogDelete = false">I accept</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                </v-card>
            </v-tab-item>
            
            <v-tab-item >
                <v-card flat>
                    <v-card-text >Mes Informations</v-card-text>
                    <v-row>
                        <v-col cols="12" sm="4">
                            image
                        </v-col>
                        <v-col cols="12" sm="8">
                            <v-row>
                                <v-col cols="12" sm="2">
                                    <v-subheader>Nom</v-subheader>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field  label="" required></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="2">
                                    <v-subheader>Prenom</v-subheader>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field  label="" required></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="2">
                                    <v-subheader>permis</v-subheader>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field  label="" required></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="2">
                                    <v-subheader>cin</v-subheader>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field  label="" required></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="2">
                                    <v-subheader>Têléphone</v-subheader>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field  label="" required></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="2">
                                    <v-subheader>adresse</v-subheader>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field  label="" required></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="2">
                                    <v-subheader>email</v-subheader>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field  label="" required></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card>
            </v-tab-item>

            <v-tab-item >
                <v-card flat>
                <v-card-text >Les Condition Generale</v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs-items>



            <div>
                <!-- <v-card elevation="3" align="center">
                    <div>
                        <v-card-text>
                           My Orders
                        </v-card-text>
                        <v-data-table :headers="headers" :search="search" :items="desserts" sort-by="name" class="elevation-1">
                            <template v-slot:[`item.is_validated`]="{ item }">
                                <span v-if="item.is_validated==1" style="color:green !important;">Valider</span>
                                <span v-if="item.is_validated==0" style="color:red !important;">En Cours</span>
                            </template>
                             <template v-slot:[`item.actions`]="{ item }">
                                <v-icon small color="blue" class="mr-2" @click="showItem(item)">mdi-message-text</v-icon>
                                <v-icon small color="red" @click="deleteItem(item)">mdi-delete</v-icon>
                            </template>
                        </v-data-table>
                        <v-dialog v-model="dialogDetails" width="500">
                            <v-card>
                                <v-card-title class="text-h5 grey lighten-2">
                                Privacy Policy
                                </v-card-title>

                                <v-card-text>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                </v-card-text>

                                <v-divider></v-divider>

                                <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="dialogDetails = false">I accept</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                         <v-dialog v-model="dialogDelete" width="500">
                            <v-card>
                                <v-card-title class="text-h5 red lighten-2">
                                    Annulation
                                </v-card-title>

                                <v-card-text>
                                    Êtes-vous sûr de annuler cet location ?
                                </v-card-text>

                                <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="dialogDelete = false">I accept</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </div>
                </v-card> -->
            </div>
        </div>
    </div>
</template>

<style>
    .content{
        width:600px;
    }
    .top_side{
        widows: 100%;
        height:27rem;
        /* background-color:gray; */
        margin-bottom:12px;
        background-size:cover  ;
       background: rgb(30,67,124);
        background: linear-gradient(0deg, rgba(9, 44, 97, 0.555) 0%, rgba(12, 38, 78, 0.878) 100%),url("../assets/cover11.jpg");
        background-repeat: no-repeat;
        background-size:cover;
        background-position: center;
       padding-top: 8rem;
       padding: auto;
        /* background-position-y: center; */
    }
    .content-bg{
        background: rgb(255,255,255);
        background: linear-gradient(90deg, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0.5) 100%),url("../assets/white-paper.jpg");
        background-repeat: no-repeat;
        background-position: right bottom;
        /* background-size: cover; */
    }

</style>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            tab: null,
            testLogin:false,
            dialogDetails:false,
            dialogDelete:false,
            itemLogin:{},
            headers: [
                { text: 'voiture', value: 'car.marque' },
                { text: 'type', value: 'car.desc' },
                { text: 'Date départ', value: 'startdate_rent' },
                { text: 'Date de dépot', value: 'findate_rent' },
                { text: 'prix', value: 'price' },
                { text: 'ville Départ', value: 'city1' },
                { text: 'ville dépot', value: 'city2' },
                { text: 'État', value: 'is_validated' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            desserts: [],
        }
    },  
    mounted() {
        // this.initial()
    },        
    methods: {
        initial(){
            axios.get('https://gesloc.ma/locationVoiture/api/booking').then(Response=>{
                console.log(Response.data)
                this.desserts=Response.data
            })
        },
        Login(){
            axios.post('http://localhost:8000/api/client/login',this.itemLogin).then(Response=>{
                console.log(Response.data)
                if(Response.data[0].id!=0){
                    axios.get('https://gesloc.ma/locationVoiture/api/booking').then(ResponseBooking=>{
                        console.log(ResponseBooking.data)
                    ResponseBooking.data.forEach(booking => {
                        if(booking.cin_client==Response.data[0].cin && booking.phone_client==Response.data[0].phone){
                            this.desserts.push(booking)
                        }
                    });
                    })
                    this.testLogin=true
                }
            })
        },
        showItem(item){
            console.log(item)
            this.dialogDetails=true
        },
        deleteItem(item){
            console.log(item)
            this.dialogDelete=true
        }
    },
}
</script>