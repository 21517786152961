<template>
    <div>
        <div class="top_side" id="about_rm">
            <div class='text-bg'>
                <p class="display-3 orange--text" style="font-weight:bold;">Qui Sommes Nous</p>
                <p class="subtitle white--text" style="font-size:20px;font-weight: bold;">-Nous sommes votre partenaire de confiance en location de voiture <br>
                    GESLOC  est une solution en ligne qui vous permet de réserver votre voiture de location à travers notre réseau de partenaire au Maroc. <br>
                    -Nous offrons la garantie d’un bon service adapté à votre style de vie et votre mobilité. <br>
                    -Un parc varié de voiture est mis à votre disposition avec une disponibilité sans faille. <br>
                    -Vous pouvez réserver une ligne au même par téléphone tout est pensé pour que votre confort bat son plein  <br>
                </p>
                <v-btn color="pink" dark @click="testdetails=!testdetails">
                    <v-icon color="white" v-if="testdetails==false">mdi-plus</v-icon>
                    <v-icon color="white" v-if="testdetails==true">mdi-close</v-icon>Détails
                </v-btn>
            </div>
        </div>
        <div class="container px-auto mx-auto">
            <div class="px-auto my-15 mx-auto">
                <v-row>
                    <v-col>
                        <span v-if="testdetails==true" style="padding:0px !important;">
                            Par son expérience dans la digitalisation des services, la société <a href="https://octobot-consulting.com/" style="color:black;"> OCTOBOT CONSULTING &TRAINING </a> a pensé  à une solution pour faciliter le choix et la location de votre voiture 24h/24h et 7j/7j. <br>
                            -Notre service et personnalisé pour rester à votre écoute et vous offrir les meilleurs choix possible pour une location sans surprise. <br>
                            -Notre plateforme innovante synchronise en temps réel avec toutes les agences de locations et vous donne l’information exacte sur la disponibilité des véhicules. <br>
                            -Enfin en vous met en contact avec des loueurs bien connu est choisi et qui respectent l’exigence de travail et de professionnalisme pour votre bien être et votre sécurité. <br>
                        </span>
                    </v-col>
                </v-row>
            </div>
        </div>
    </div>
</template>

<style>
    .content{
        width:600px;
    }

    .top_side{
        widows: 100%;
        height:27rem;
        /* background-color:gray; */
        margin-bottom:12px;
        background-size:cover  ;
        background: rgb(30,67,124);
        background: linear-gradient(0deg, rgba(9, 44, 97, 0.555) 0%, rgba(12, 38, 78, 0.878) 100%),url("../assets/cover11.jpg");
        background-repeat: no-repeat;
        background-size:cover;
        background-position: center;
       padding-top: 8rem;
       padding: auto;
        /* background-position-y: center; */
    }
    .content-bg{
        background: rgb(255,255,255);
        background: linear-gradient(90deg, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0.5) 100%),url("../assets/white-paper.jpg");
        background-repeat: no-repeat;
        background-position: right bottom;
        /* background-size: cover; */
    }

</style>

<script>
export default {
    data() {
        return {
            testdetails:false
        }
    },  
    methods:{
        // details(){
        //     alert('hello')
        //     this.testdetails=!this.testdetails
        // }
    }  
}
</script>