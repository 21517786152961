<template>
  <!-- App.vue -->

  <v-app>
    <div style="width: 100%; background-color:rgb(70 70 70 / 83%) !important;position: fixed; z-index:9999;">
      <div class="container">
        <b-navbar toggleable="lg" type="dark" variant=""  class="p-0">
            <b-navbar-brand href="#" class="ml-5 p-0" style="border-radius:15px;margin: 0px !important;" to="/"  @click="goToHome">
              <img src="./assets/GESLOC.png" style="width: 50px; height:50px;">
            </b-navbar-brand>
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
            <b-collapse id="nav-collapse" is-nav class="justify-content-end">
              <b-navbar-nav>
                <b-nav-item href="" class="mx-3" active to="/" @click="goToHome">Accueil</b-nav-item>
                <b-nav-item  class="mx-3" to="/" @click="goToRes">Réserver</b-nav-item>
                <b-nav-item href="#" class="mx-3" to="/about" @click="goToAbout">Qui Sommes Nous</b-nav-item>
                <b-nav-item href="" class="mx-3" to="/contacts" @click="goToContact"> Contactez Nous</b-nav-item>
                <b-nav-item href="" class="mx-3" to="/login"> Login </b-nav-item>
              </b-navbar-nav>
            </b-collapse>
        </b-navbar>
      </div>
    </div>
    <!-- ------------------- content ------------------- -->
    <!-- Sizes your content based upon application components -->
    <v-main class="vmain">
      <!-- Provides the application the proper gutter -->
      <v-container fluid style="margin: 0px !important;padding: 0px !important;">
        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>
    <!-- ----------------------------- FOOTER --------------------------->
    <div>
      <v-footer dark class="mt-16 white--text mx-auto" style="background-color: rgb(17, 19, 32) !important;">
        <v-card style="background-color: transparent;border: none;border-color: transparent !important;box-shadow:none;" class="mx-auto">
          <v-card-text>
            <v-btn v-for="icon in icons" :key="icon" class="mx-4 white--text" icon>
              <v-icon size="24px">
                {{ icon }}
              </v-icon>
            </v-btn>
          </v-card-text>

          <v-card-text class="white--text pt-0">
            <v-row>
              <v-col cols="12" sm="4">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="mb-2"><h2>Qui Sommes Nous</h2></v-list-item-title>
                    <hr color="gray" style="width: 9rem; flex:none; margin: 0 auto 13px; padding-bottom:">
                    <v-list-item-subtitle class="mb-2"><a href="" style="text-decoration: none; color: darkgray">Réservation</a></v-list-item-subtitle>
                    <v-list-item-subtitle class="mb-2"><a href="" style="text-decoration: none; color: darkgray">Voitures</a></v-list-item-subtitle>
                    <v-list-item-subtitle class="mb-2"><a href="" style="text-decoration: none; color: darkgray">Agences</a></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="4">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="mb-2">
                      <h2>F.A.Q?</h2>
                      <hr color="gray" style="width: 8rem; flex:none; margin: 0 auto 13px; padding-bottom:">
                    </v-list-item-title>
                    <v-list-item-subtitle class="mb-2" @click="goToAbout">
                      <router-link to="/about" style="text-decoration: none; color: darkgray;" >À propos de nous</router-link>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="mb-2">
                      <router-link to="/about" style="text-decoration: none; color: darkgray;">Go to Foo</router-link>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="mb-2" @click="goToRes">
                      <router-link to="/" style="text-decoration: none; color: darkgray;">voitures</router-link>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="mb-2">
                      <router-link to="/about" style="text-decoration: none; color: darkgray;">test</router-link>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="4">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="mb-2"><h2>Contactez Nous</h2></v-list-item-title>
                    <hr color="gray" style="width: 8rem; flex:none; margin: 0 auto 13px; padding-bottom:">
                    <v-list-item-subtitle><v-icon>mdi-map-marker</v-icon> PLACE FLORENCE IMB LA MAMDA 3 EME <br> ETG APPARTMENT 32 VILLE NOUVELLE FES</v-list-item-subtitle>
                    <v-list-item-subtitle><v-icon>mdi-email</v-icon>contact@gesloc.ma</v-list-item-subtitle>
                    <v-list-item-subtitle><v-icon>mdi-phone</v-icon>+212 5-32-09-09-02</v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <v-icon>mdi-cellphone</v-icon>
                      +212 6-65-88-80-07</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-text class="white--text">
            2021 —
            <a href="https://octobot-consulting.com/">
              <strong>All Rights Reserved by Octobot CONSULTING & TRAINING</strong>
            </a>
          </v-card-text>
        </v-card>
      </v-footer>
    </div>
  </v-app>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.crd_effect{
  transition: 0.3s ease-in-out;
}
.crd_effect:hover{
  transform: scale(1.03);
}

@media (max-width: 380px) {
  #top_h1 {
    font-size: 50px;
    color: red;
  }
  .top_p {
    font-size: 15px;
  }
}
</style>

<script>

// import axios from "axios";

export default {
  data() {
    return {
      icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
    };
  },
  // computed: {
  //   
  // },
  mounted() {
    //
  },
  methods: {
    goToRes(){
      location.href = "#form_rm2";
    },
    goToAbout(){
      location.href = "#about_rm";
    },
    goToContact(){
      location.href = "#contact_rm";
    },
    goToHome(){
      location.href = "#home_rm";
    }
  },
};
</script>